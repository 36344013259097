const meditation = {
    data: {
        textOverlays: [
            { title: "Sit comfortably and repeat silently: I am", contents: "Tolerant, forgiving and compassionate" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Grateful, appreciative and thankful" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Sincere, loving and selfless" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Joyful, carefree and playful" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Considerate, thoughtful and helpful" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Strong, Energised and Inspired" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Learning my purpose, and finding fulfilment" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Relaxed, calm and peaceful" },
            { title: "Sit comfortably and repeat silently: I am", contents: "A spiritual, caring, human being" },
            { title: "Sit comfortably and repeat silently: I am", contents: "Tension, frustration and anger" },
        ]
      },
      methods: { }
};

export default meditation;

// textOverlays: [
//     { title: "I am (TFC)", contents: "Tolerant, forgiving and compassionate" },
//     { title: "I am (GAT)", contents: "Grateful, appreciative and thankful" },
//     { title: "I am (SLS)", contents: "Sincere, loving and selfless" },
//     { title: "I am (JCP)", contents: "Joyful, carefree and playful" },
//     { title: "I am (CTH)", contents: "Considerate, thoughtful and helpful" },
//     { title: "I am (SEI)", contents: "Strong, Energised and Inspired" },
//     { title: "I am (LPF)", contents: "Learning my purpose, and finding fulfilment" },
//     { title: "I am (RCP)", contents: "Relaxed, calm and peaceful" },
//     { title: "I am (SCH)", contents: "A spiritual, caring, human being" },
//     { title: "I Let go of", contents: "Tension, frustration and anger" },
// ]