<template>
  <div>
    <div v-if="$route.name === 'Body'" class="px-5">
      <v-container style="padding-bottom: 5vh;">
        <h1>Stretching and Yoga</h1>
        <p>
          Click the yoga-button
          <v-icon medium color="light-green lighten-1">mdi-arrow-up-bold-box-outline</v-icon>above to start your daily bite size sessions!
        </p>
        <p>
          <i>Only gently stretch to the point you feel comfortable. Over time you will become more agile.</i>
        </p>
        <p>Yoga is a Sanskrit term meaning 'to join, unite or yoke together', and the essential purpose of yoga is to bring together body, mind and spirit into a harmonious whole.</p>
        <p>The central methods of yoga are physical postures or 'asanas' and movement, breathing techniques or 'pranayama' and meditation.</p>
        <p>Yoga is exercise for our internal body and organs.</p>
        <!-- <div v-if="$route.name === 'Affirmations'">
        <v-btn @click="goToMind">Previous Page</v-btn>
      </div>
      <div v-if="$route.name === 'Body'">
        <v-btn @click="goToBody">Previous Page</v-btn>
      </div>
       <div v-if="$route.name === 'Soul'">
        <v-btn @click="goToSoul">Previous Page</v-btn>
        </div>-->
        <!-- <div v-else>
        <v-btn @click="goToHome">Back To Home</v-btn>
        </div>-->
      </v-container>
      <div class="donateButton">
        <!-- <v-container>
          <v-flex xs12>
            <v-card dark color="primary">
              <v-card-text class="px-6">
                <i>There is no obligation to donate. The maximum accepted is one pound which allows the Author to continually improve and add new contents to this Website/App. Thank You :)</i>
                <v-spacer></v-spacer>
                <br />
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="AND8A55TB3MPL" />
                  <input
                    style="border-radius: 0.75rem;width:25vh"
                    type="image"
                    :src="paypalDonateButton"
                    border="0"
                    name="submit"
                    title="Donate Now - PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img alt border="0" :src="paypalPixel" width="1" height="1" />
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-container> -->
      </div>
      <br />
    </div>

    <!--  
      <div style="max-height: 900px">
        <marquee-text :duration="50">
          <img :src="scrollImage" width="100%" style="zoom: 0.27"/>
        </marquee-text>
    </div>-->

    <!-- <router-view> render children components -->
    <v-spacer></v-spacer>
    <router-view></router-view>
  </div>
</template>

<script>
// import MarqueeText from "vue-marquee-text-component";
// import bg1 from "@/assets/backgrounds/VectorPanView.jpg";
import paypalDonateButton from "@/assets/paypalButton.png";
import paypalPixel from "@/assets/PaypalPixel.gif";

export default {
  data() {
    return {
      paypalDonateButton: paypalDonateButton,
      paypalPixel: paypalPixel

      // scrollImage: bg1
    };
  },
  components: {
    // MarqueeText: MarqueeText
  },
  methods: {
    goToMind() {
      // Again if you need some params for that route, you can add into the obj
      // Literally same object structure with <router-link>
      this.$router.push({ name: "Mind" });
    },
    goToBody() {
      // Again if you need some params for that route, you can add into the obj
      // Literally same object structure with <router-link>
      this.$router.push({ name: "Body" });
    },
    goToSoul() {
      // Again if you need some params for that route, you can add into the obj
      // Literally same object structure with <router-link>
      this.$router.push({ name: "Soul" });
    },
    goToHome() {
      // Again if you need some params for that route, you can add into the obj
      // Literally same object structure with <router-link>
      this.$router.push({ name: "Home" });
    },
    computed: {
      checkRoute() {
        return this.$route.name === "Body" ? true : false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bolder;
}
h2 {
  font-size: 1.25rem;
  font-weight: bolder;
}
.donateButton {
  padding-bottom: 7vh;
  padding-top: 7vh;
  text-align: center;
}
</style>