const yoga = {
    data: {
        textOverlays: [
            { title: "Downward Facing Dog", contents: "Adho Mukha Svanasana" },
            { title: "Cobblers Pose", contents: "Baddha Konasana" },
            { title: "Child Pose", contents: "Balasana" },
            { title: "Cobra Pose", contents: "Bhujangasana" },
            { title: "Lying Down Pose", contents: "Savasana" },
            { title: "Mountain Pose", contents: "Tadasana" },
            { title: "Triangle Pose", contents: "Trikonasana" },
            { title: "Chair Pose", contents: "Utkatasana" },
            { title: "Warrior Pose", contents: "Virabhadrasana" },
            { title: "Tree Pose", contents: "Vrikshasana" },
        ]
      },
      methods: { }
};

export default yoga;