<template>
  <div>
    <h1>Page Not Found</h1>
    <p>Sorry, but the page you were trying to view does not exist.</p>
  </div>
</template>

<script>
export default {};
</script>


