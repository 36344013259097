<template>
  <div>
    <div v-if="$route.name === 'Soul'" class="px-5">
      <v-container style="padding-bottom: 5vh;">
        <h1>Meditation for the Soul</h1>

        <p>
          Click the meditation-button
          <v-icon medium color="light-green lighten-1">mdi-arrow-up-bold-box-outline</v-icon>above to start your daily bite size sessions!
        </p>
        <p>
          <i>When meditating, sit comfortably and relaxed. At the top of each picture, you will see a short phrase, which we are using as the focus point so silently repeat this phrase. Every time you feel your thoughts are drifting, bring yourself back to the focus point by repeating the phrase.</i>
        </p>

        <p>Meditation is a practice where an individual uses a technique, such as mindfulness, or focusing the mind on a particular object, thought, or activity, to train attention and awareness, and achieve a mentally clear and emotionally calm and stable state.</p>
        <p>Take control of your mind, and you take control of your life.</p>
      </v-container>

      <div v-if="$route.name !== 'Affirmations'" class="donateButton">
        <!-- <v-container>
          <v-flex xs12>
            <v-card dark color="primary">
              <v-card-text class="px-6">
                <i>There is no obligation to donate. The maximum accepted is one pound which allows the Author to continually improve and add new contents to this Website/App. Thank You :)</i>
                <v-spacer></v-spacer>
                <br />
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="AND8A55TB3MPL" />
                  <input
                    style="border-radius: 0.75rem;width:25vh"
                    type="image"
                    :src="paypalDonateButton"
                    border="0"
                    name="submit"
                    title="Donate Now - PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img alt border="0" :src="paypalPixel" width="1" height="1" />
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-container> -->
      </div>
      <br />
    </div>

    <v-spacer></v-spacer>
    <router-view></router-view>
  </div>
</template>

<script>
import paypalDonateButton from "@/assets/paypalButton.png";
import paypalPixel from "@/assets/PaypalPixel.gif";

export default {
  data() {
    return {
      paypalDonateButton: paypalDonateButton,
      paypalPixel: paypalPixel,
      boolean1: true,
      boolean2: false
    };
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bolder;
}

h2 {
  font-size: 1.25rem;
  font-weight: bolder;
}
.donateButton {
  padding-bottom: 7vh;
  padding-top: 7vh;
  text-align: center;
}
</style>
