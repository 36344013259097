 <template>
  <v-footer fixed padless>
    <v-card flat tile width="100%" color="primary" dark class="text-center">
      <v-card-text>
        <!-- If you don't need any params you can use basically -->
        <!-- :to="link.pathName" (name field in the route object) -->
        <!-- Otherwise, you need to use obj and tell it explicity -->
        <!-- This example contains complex object structure rather than simple name string -->
        <router-link
          v-for="(link, key) in links"
          :key="key"
          :to="{name: link.pathName, params: link.params}"
        >
          <!-- <v-btn v-if="link.pathName === 'Home'" color="rgb(255, 0, 0, 0.0)" x-small class="mx-1">
            <span>
              <v-icon small>{{link.icon}}</v-icon>
            </span>
          </v-btn>-->
          <span v-if="link.pathName === 'Home'">
            <v-icon  class="px-3" medium >{{link.icon}}</v-icon>
          </span>

          <v-btn v-else color="blue-grey darken-3" medium class="mx-1 px-2">
            <span>
              <b>{{link.pathName}}</b>
            </span>
          </v-btn>
        </router-link>

        <!-- TODO: Assignment: Create navigator buttons with a method  -->
        <!-- without using using <router-link> approach -->
        <!-- You'll find an example in Mind.vue if you stuck -->
      </v-card-text>

      <v-divider></v-divider>

      <!-- <v-card-text class="white--text"> -->
      <!-- {{ new Date().getFullYear() }} — <strong>{{ projectName }}</strong> -->
      <!-- </v-card-text> -->
    </v-card>
  </v-footer>
</template>

<script>
export default {
  // TODO: Add link paths after routing is done
  data: () => ({
    projectName: "Vue Testing",
    links: [
      {
        icon: "mdi-home",
        pathName: "Home",
        params: {}
      },
      {
        icon: "mdi-email",
        pathName: "Mind",
        params: { id: 1 }
      },
      {
        icon: "mdi-calendar",
        pathName: "Body",
        params: {}
      },
      {
        icon: "mdi-delete",
        pathName: "Soul",
        params: {}
      }
      // {
      //   icon: "mdi-delete",
      //   pathName: "Bio",
      //   params: {}
      // },
    ]
  }),
  created() {
    console.log("routers", this.$router);
  }
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>