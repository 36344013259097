<template>
  <div>
    <div v-if="window.width <= window.height" class="portrait">
      <v-container>
        <div class="overlayTextPortrait">
          <div
            dark
            color="secondary"
            style="padding: 0.0rem;font-size: 0.7rem"
            text-center
            class="justify-center"
          >({{backgroundId}}) {{title.toUpperCase()}}</div>

          <!-- <img class="preview" :class="[computedOrientation]" :src="preview" :alt="computedOrientation"> -->

          <div dark color="secondary" style="padding: 0.0rem" text-center class="justify-center">
            <b>{{contents.toUpperCase()}}</b>
          </div>
        </div>
      </v-container>
      <div class="bgImageContainerPortrait">
        <div>
          <img :src="backgroundImageSrc" :alt="backgroundImageAlt" width="100%" />
        </div>
        <div
          v-if="backgroundId == 1 || backgroundId == 4 || backgroundId == 6 || backgroundId == 7 || backgroundId == 8"
        >
          <div class="bird-container bird-container--one">
            <div class="bird bird--one" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--two">
            <div class="bird bird--two" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--three">
            <div class="bird bird--three" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--four">
            <div class="bird bird--four" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>
        </div>
      </div>

      <div class="gridContainerPortrait">
        <v-container grid-list-md>
          <v-layout>
            <v-btn-toggle
              v-model="toggle_exclusive"
              grid-list-md
              style="padding-bottom: 40vh;grid-template-columns: auto auto auto;display: grid;position: absolute; top: 0; left: 0; width: 100%;"
            >
              <v-flex v-for="(pose, key) in backgrounds" :key="key">
                <v-btn
                  class="px-1"
                  style="min-width: 4vw; width: 30vw;white-space: nowrap;overflow: hidden;"
                  text-center
                  dark
                  color="blue-grey darken-2"
                  v-on:click="swapImage(`${key}`)"
                >{{pose.alt}}</v-btn>
              </v-flex>
            </v-btn-toggle>
          </v-layout>
        </v-container>
      </div>

      <div class="audioContainerPortrait">
        <v-container>
          <v-flex xs12>
            <v-card dark color="primary">
              <v-card-text class="px-3">
                <audio class="audioControls" loop autoplay controls :src="backgroundAudio"></audio>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-container>
      </div>

      <!-- <router-view> render children components -->
    </div>

    <!-- *** LANDSCAPE *** -->
    <div v-if="window.width > window.height" class="landscape">
      <div class="overlayTextLandscape">
        <h4>({{backgroundId}}) {{title.toUpperCase()}}</h4>
        <v-spacer></v-spacer>
        <h2>{{contents.toUpperCase()}}</h2>
      </div>

      <div class="bgImageContainerLandscape">
        <div>
          <transition name="fade" mode="out-in">
            <img :src="backgroundImageSrc" :alt="backgroundImageAlt" width="100%" />
          </transition>
        </div>

        <div
          v-if="backgroundId == 1 || backgroundId == 4 || backgroundId == 6 || backgroundId == 7 || backgroundId == 8"
        >
          <div class="bird-container bird-container--one">
            <div class="bird bird--one" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--two">
            <div class="bird bird--two" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--three">
            <div class="bird bird--three" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>

          <div class="bird-container bird-container--four">
            <div class="bird bird--four" :style="{ backgroundImage: `url(${birds})` }"></div>
          </div>
        </div>
      </div>
      <div class="gridContainerLandscape">
        <v-container grid-list-md>
          <v-layout>
            <v-btn-toggle
              v-model="toggle_exclusive"
              grid-list-md
              style="grid-template-columns: auto auto auto;display: grid;position: absolute; top: 0%; left: 0%; width: 40%;"
            >
              <v-flex v-for="(pose, key) in backgrounds" :key="key">
                <v-btn
                  style="min-width: 4.5vw; width: 12.5vw;white-space: nowrap;overflow: hidden;height: 10vh"
                  text-center
                  dark
                  color="blue-grey darken-2"
                  v-on:click="swapImage(`${key}`)"
                >{{pose.alt}}</v-btn>
              </v-flex>
            </v-btn-toggle>
          </v-layout>
        </v-container>

        <div class="audioContainerLandscape">
          <v-container>
            <v-flex xs12>
              <v-card color="rgba(0, 0, 0, 0.0)">
                <v-card-text class="px-3">
                  <audio
                    id="audioControls"
                    class="audioControls"
                    autoplay
                    loop
                    controls
                    :src="backgroundAudio"
                  ></audio>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-container>

          <v-card v-if="displayOrientationMsg" dark color="orange darken-4" justify="space-around">
            <v-card-text text-center class="justify-center">
              <b>If using a Tablet, Turn Your Device Landscape for Best Results</b>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>


       
        <!-- <img :src="backgroundImageSrc" :alt="backgroundImageAlt" width="100%" /> -->
      
        <!-- <pano :title="backgroundImageAlt" width="720" height="480" :bundle="backgroundImageSrc" format="png"></pano> -->
     

<script>
// import MarqueeText from "vue-marquee-text-component";
// import VueAudio from "vue-audio";
// import { MobileOrientation } from "mobile-orientation";
import { isMobile } from "mobile-device-detect";

import bg1 from "@/assets/backgrounds/WoodsLeafsFalling.jpg";
import bg2 from "@/assets/backgrounds/SidmouthJacobsHill.jpg";
import bg3 from "@/assets/backgrounds/LillyPond.jpg";
import bg4 from "@/assets/backgrounds/SandyBeachRock.jpg";
import bg5 from "@/assets/backgrounds/WoodsPinkFlowers.jpg";
import bg6 from "@/assets/backgrounds/WoodsTreeColours.jpg";
import bg7 from "@/assets/backgrounds/HillSideView.jpg";
import bg8 from "@/assets/backgrounds/DawnRiverTree.jpg";
import bg9 from "@/assets/backgrounds/GardenRiver2.jpg";

import a1 from "@/assets/audio/meditation/MantraPart2TheyDreamByDay.mp3";
import a2 from "@/assets/audio/meditation/WaterCrystals.mp3";
import a3 from "@/assets/audio/meditation/AtTheMercyOfAMomentRandAldo.mp3";
import a4 from "@/assets/audio/meditation/BirdofPassageJakobAhlbom.mp3";
import a5 from "@/assets/audio/meditation/CrystalisJosephBeg.mp3";
import a6 from "@/assets/audio/meditation/EnlightenmentYonderDale.mp3";
import a7 from "@/assets/audio/meditation/CognitiveWavesJosephBeg.mp3";
import a8 from "@/assets/audio/meditation/KnowYouHaveBeenThereAveAir.mp3";
import a9 from "@/assets/audio/meditation/MantraPart1TheyDreamByDay.mp3";

// import a1 from "@/assets/audio/meditation/MantraPart2TheyDreamByDay.mp3";
// import a2 from "@/assets/audio/meditation/WaterCrystals.mp3";
// import a3 from "@/assets/audio/meditation/ImaginaryWaterfallsJosephBeg.mp3";
// import a4 from "@/assets/audio/affirmations/NightWalkGavinLuke.mp3";
// import a5 from "@/assets/audio/meditation/KnowYouHaveBeenThereAveAir.mp3";
// import a6 from "@/assets/audio/meditation/MorningBirdlifeAutonomicSensations.mp3";
// import a7 from "@/assets/audio/meditation/MysticMountainJonBjork.mp3";
// import a8 from "@/assets/audio/meditation/KnowYouHaveBeenThereAveAir.mp3";
// import a9 from "@/assets/audio/meditation/MantraPart1TheyDreamByDay.mp3";

// import BGOverlay from "@/components/static/BGOverlay";
import meditationStore from "@/store/meditation";
import birdsBrown from "@/assets/birdsBrown.svg";
import birdsWhite from "@/assets/birdsWhite.svg";
// const orientation = new MobileOrientation();
// let currentAudio = document.getElementById("audioControls");
export default {
  components: {
    // BGOverlay /* same as: BGOverlay: BGOverlay, */,
    // MarqueeText: MarqueeText,
    // "vue-audio": VueAudio
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      //   portrait: null,
      // landscape: null,
      // orientation: new MobileOrientation({ withTouch: true }),

      text: "center",
      icon: "justify",
      toggle_exclusive: 0 /* 0 = 1st btn */,

      displayOrientationMsg: false,
      isMobile: isMobile,
      // orientation: new MobileOrientation(),
      selected: 0,
      // title: meditationStore.data.textOverlays[0].title,
      // contents:  meditationStore.data.textOverlays[0].contents
      imageLoaded: false
      // file1: "dist/sample.mp3",
      // backgroundAudio: a1,
      // scrollImage: bg1
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // this.orientation.on('resize', state => {
    //   console.warn(`Event: 'Resize', Orientation: '${state}'`)
    // })
    // this.orientation.on('portrait', () => {
    //   console.warn(`Event: 'Portrait'`)
    // })
    // this.orientation.on('landscape', () => {
    //   console.warn(`Event: 'Landscape'`)
    // })
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  // beforeDestroy() {
  //   this.orientation.destroy();
  // },
  computed: {
    // btnStates() {
    //   return this.backgrounds.map(btn => btn.state);
    // },
    birds() {
      if (this.selected == 6 || this.selected == 7) {
        return birdsBrown;
      } else if (this.selected == 3) {
        return birdsWhite;
        
      } else {
        return birdsBrown;
      }
    },
    isMobileCheck() {
      return isMobile;
    },
    // computedOrientation() {
    //   return this.orientation.state || ''
    // },
    preview() {
      // if (this.orientation.isPortrait) {
      //   return this.portrait
      // }
      // return this.landscape
    },
    // screenOrientation() {
    //   return this.orientation;
    // },
    // isPortrait() {
    //   return this.orientation.isPortrait;
    // },
    // isLandscape() {
    //   return this.orientation.isLandscape;
    // },
    backgroundImageSrc() {
      return this.backgrounds[this.selected].imageName;
    },
    backgroundImageAlt() {
      return this.backgrounds[this.selected].alt;
    },
    backgroundAudio() {
      return this.backgrounds[this.selected].audio;
    },
    backgroundId() {
      return this.backgrounds[this.selected].id;
    },
    title() {
      return meditationStore.data.textOverlays[this.selected].title;
    },
    contents() {
      return meditationStore.data.textOverlays[this.selected].contents;
    },
    backgrounds: function() {
      return [
        { imageName: bg1, id: 1, alt: "1", audio: a1, state: true },
        { imageName: bg2, id: 2, alt: "2", audio: a2, state: false },
        { imageName: bg3, id: 3, alt: "3", audio: a3, state: false },
        { imageName: bg4, id: 4, alt: "4", audio: a4, state: false },
        { imageName: bg5, id: 5, alt: "5", audio: a5, state: false },
        { imageName: bg6, id: 6, alt: "6", audio: a6, state: false },
        { imageName: bg7, id: 7, alt: "7", audio: a7, state: false },
        { imageName: bg8, id: 8, alt: "8", audio: a8, state: false },
        { imageName: bg9, id: 9, alt: "9", audio: a9, state: false }
      ];
    }
  },
  watch: {
    isPortrait(val) {
      console.log("isPortrait: " + val);
    },
    isLandscape(val) {
      console.log("isLandscape: " + val);
    }
  },
  mounted() {
    this.imageLoaded = true;
    // setTimeout(() => {
    //   this.displayOrientationMsg = false;
    // }, 5000);

    // this.currentAudio.play(); // autoplay
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    activeButton(status) {
      return status === true ? "activeClass" : "removeActive";
    },
    swapImage(bgId) {
      this.selected = bgId;
      // activeButton = (pose.id -1) === selected ? 'activeClass' : 'removeActive'
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
h3 {
  font-size: 0.9rem;
}
h2 {
  font-size: 1rem;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #0d4777;
  color: #0d4777;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Chrome, Safari, Opera */
// @-webkit-keyframes zoom {
//     from {
//     	-webkit-transform: scale(1,1);
//     }
//     to {
//     	-webkit-transform: scale(1.5,1.5);
//     }
// }

/* Standard syntax */
@keyframes zoom {
   from {
        transform: scale(1.0);
   }
   to {
        transform: scale(1.5);
   }
}


img {
    animation: zoom 50s alternate infinite;
}

vue-audio {
  padding: 2.5%;
}
// .portrait {
//   padding-bottom: 15vh;
// }
// .landscape {
//   padding-bottom: 15vh;
// }
#meditationSession {
  position: relative;
  font-size: 1.5rem;
  font-weight: bolder;
  top: 2rem;
  left: 1rem;
}

/* PORTRAIT */
.overlayTextPortrait {
  background-color: #0b365b;
  // opacity: 0.75;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  z-index: 9;
  padding: 0.25rem;
  line-height: 3vh;
}
.bgImageContainerPortrait {
  max-height: 40vh;
  // background-color: #2196f3;
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute;
  top: 7vh;
  right: 0%;
  width: 100%;
  // max-height: 600px;
  overflow-y: hidden;
}
.audioControls {
  width: 100%;
}
.gridContainerPortrait {
  position: absolute;
  top: 39vh;
  left: 1%;
  width: 98%;
  // display: grid;
  // grid-template-columns: auto auto auto;
  // background-color: #0d4777;
  // padding: 0.9rem;
  padding-bottom: 40vh;
}
.audioContainerPortrait {
  position: fixed;
  bottom: 6.5vh;
  left: 0%;
  width: 100%;
  text-align: center;
}

/* LANDSCAPE */
.overlayTextLandscape {
  background-color: #0b365b;
  opacity: 0.75;
  position: absolute;
  top: 0%;
  left: 42%;
  width: 58%;
  font-size: 0.825rem;
  text-align: center;
  z-index: 9;
  padding: 0.25rem;
  line-height: 4.5vh;
}
.bgImageContainerLandscape {
  // background-color: #2196f3;
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute;
  top: 0%;
  right: 0%;
  width: 58%;
  // max-height: 600px;
  overflow-y: hidden;
}
.gridContainerLandscape {
  position: absolute;
  top: 7vh;
  left: 0%;
  width: 42%;
  // display: grid;
  // grid-template-columns: auto auto auto;
  // background-color: #0d4777;
  padding: 0.9rem;
  padding-bottom: 40vh;
}
.audioContainerLandscape {
  // position: fixed;
  // bottom: 10vh;
  // left: 0%;
  margin-top: 22.5vh;
  padding-top: 2.25vh;
  width: 100%;
  text-align: center;
}

// .container {
// 	// z-index: 1;
// 	position: relative;
// 	// overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	// min-height: 100vh;
// 	min-height: 35rem;
// 	// background-image: linear-gradient(to bottom,  rgba(255,168,76,0.6) 0%,rgba(255,123,13,0.6) 100%), url('https://images.unsplash.com/photo-1446824505046-e43605ffb17f');
// 	// background-blend-mode: soft-light;
// 	// background-size: cover;
// 	// background-position: center center;
// 	padding: 2rem;
// }

.bird {
  // background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;

  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 1s;
    animation-delay: -0.5s;
  }

  &--two {
    animation-duration: 0.9s;
    animation-delay: -0.75s;
  }

  &--three {
    animation-duration: 1.25s;
    animation-delay: -0.25s;
  }

  &--four {
    animation-duration: 1.1s;
    animation-delay: -0.5s;
  }
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;

  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 15s;
    animation-delay: 0;
  }

  &--two {
    animation-duration: 16s;
    animation-delay: 1s;
  }

  &--three {
    animation-duration: 14.6s;
    animation-delay: 9.5s;
  }

  &--four {
    animation-duration: 16s;
    animation-delay: 10.25s;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }

  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
</style>