<template>
  <v-app-bar app color="primary" dark style="z-index: 10">
    <v-toolbar-title v-if="checkTitle">
      <b>MBS</b>
    </v-toolbar-title>
    <v-btn
      v-if="$route.name !== 'Home'"
      @click="$router.go(-1)"
      class="mx-2"
      color="blue-grey darken-2"
      dark
      fab
      small
    >
      <v-icon style="padding-left: 0.59rem" dark left>mdi-arrow-left</v-icon>
    </v-btn>

    <div
      class="pull-right"
      style="
        margin-top: 5.5px;
        padding-right: 1.5vw;
        background-color: transparent;
        position: absolute;
        right: 1.5vw;
}
      "
    >
      <span>
        <a id="" href="https://www.himgiri.co.uk/education/" target="_blank">
          <img width="50px" :src="fssLogo" />
        </a>
      </span>
    </div>

    <!-- <a class="ma-2" color="orange darken-2" dark style="color: white" @click="$router.go(-1)"><v-icon dark left>mdi-arrow-left</v-icon>Back</a> -->
    <v-spacer v-if="title && checkRoute">
      <div class="titleContainer">
        <v-toolbar-title>
          <b v-if="title == 'Affirmations'">Affirmations and Quotes</b>
          <b v-else>{{ title }}</b>
        </v-toolbar-title>
      </div>
    </v-spacer>
    <!-- <div v-if="$route.name === 'Body'">
      <v-btn @click="goToBody">Previous Page</v-btn>
    </div>
    <div v-if="$route.name === 'Soul'">
      <v-btn @click="goToSoul">Previous Page</v-btn>
    </div>-->

    <v-spacer></v-spacer>

    <router-link
      v-for="(button, key) in buttons"
      :key="key"
      :to="{ name: button.pathName, params: button.params }"
    >
      <v-btn rounded color="blue-grey darken-3" class="mx-4">
        <span>
          <v-icon large color="light-green lighten-1"
            >mdi-arrow-up-bold-box-outline</v-icon
          >
          {{ button.name }}
        </span>
      </v-btn>
    </router-link>

    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import mbsLogo from "@/assets/mbs-logo-round.png";
import fssLogo from "@/assets/logo.png";

export default {
  data: () => ({
    links: [
      {
        icon: "mdi-home",
        pathName: "Home",
        params: {},
      },
    ],
    mbsLogo: mbsLogo,
    fssLogo: fssLogo,
  }),
  computed: {
    checkTitle() {
      return this.$route.name === "Home" ? true : false;
    },
    // checkAff() {
    //   return this.$route.name === "Affirmation" ? true : false;
    // },
    checkRoute() {
      return this.$route.name !== "Home" &&
        this.$route.name !== "Mind" &&
        this.$route.name !== "Body" &&
        this.$route.name !== "Soul"
        ? true
        : false;
    },
  },
  props: ["buttons", "title"],
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
.titleContainer {
  padding-left: 1.59rem;
  text-align: right;
}
</style>
