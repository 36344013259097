<template>
  <div>
    <div class="px-5">
      <v-container style="padding-bottom: 7vh;">
        <h1>Take control of your mind with affirmations, and begin to take control of your life.</h1>
        <p>
          Click the affirmation-button
          <v-icon medium color="light-green lighten-1">mdi-arrow-up-bold-box-outline</v-icon>above to help break old thinking patterns!
        </p>

        <p>On a daily, weekly and monthly bases, we will have different feelings. How we interpret these feelings and what actions we take, if any, will determine the outcome or shape our future.</p>

        <p>Affirmations can help condition the mind, interrupt old patterns and help us to think more positively. It's like changing a story for a better one, a story that supports your needs more. If you keep telling yourself the same old story, we soon live that story by defining who we are and limiting ourselves. Tell yourself a different story, a more positive empowering one, then we soon start to live a more empowering life.</p>
        <p>We all have rituals consciously or sub-consciously, either external (i.e. in our actions), or internally (the dialogue going on in our mind). By changing the ritual, and possibly your actions, this is also a way of changing your story.</p>

        <p>Also remember, the circumstances only have the meaning we give it. So by changing the meaning we give it, can also change how we feel or preserve the situation, and as a result, can lead us to take a different action, or no action.</p>
      </v-container>

    </div>

    <!-- You can reach route object directly on html template -->
    <!-- {{$route.params}} <br> -->

    <!-- <div class="px-5">
      <v-btn @click="goToHome">Back To Home</v-btn>
    </div>-->
    <!-- <v-alert color="warning" >
            If you try to reach without "/Mind" without required param, you will get 404
    </v-alert>-->

    <!-- <h3>For header examples, take a look at <router-link to="body">BODY</router-link></h3> -->
    <v-spacer></v-spacer>
    <router-view></router-view>
  </div>
</template>

<script>
// import paypalDonateButton from "@/assets/paypalDonateButton.gif";

export default {
  created() {
    // This is called when the component generated
    this.getRouteDetails();
  },
  data() {
    return {
      // paypalDonateButton: paypalDonateButton
    };
  },
  methods: {
    // You can reach route object in here as well
    getRouteDetails() {
      const routeDetails = this.$route;
      console.log(routeDetails);
    },
    // You can go any route without using <router-link>
    // This is useful when you need to control some logic in JS level
    goToHome() {
      // Again if you need some params for that route, you can add into the obj
      // Literally same object structure with <router-link>
      this.$router.push({ name: "Home" });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bolder;
}

h2 {
  font-size: 1.25rem;
  font-weight: bolder;
}

.donateButton {
  padding-bottom: 7vh;
  text-align: center;
}
</style>