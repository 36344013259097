<template>
  <div>
    <div v-if="window.width <= window.height" class="portrait">
      <div class="overlayTextPortrait">
        <h3>{{title.toUpperCase()}}</h3>
        <!-- - {{contents.toUpperCase()}} -->
      </div>
      <div class="bgVideoContainerPortrait">
        <div>
          <video
            v-on:pause="updatePaused"
            v-on:ended="videoEnded"
            :id="videoId"
            controls
            :src="backgroundVideoSrc"
            type="video/mp4"
            :poster="posterImage"
          ></video>
        </div>
      </div>

      <div class="gridContainerPortrait">
        <v-container grid-list-md>
          <v-layout>
            <v-btn-toggle
              v-model="toggle_exclusive"
              grid-list-md
              style="padding-bottom: 40vh;grid-template-columns: auto auto auto;display: grid;position: absolute; top: 0; left: 0; width: 100%;"
            >
              <v-flex v-for="(pose, key) in backgrounds" :key="key">
                <v-btn
                  class="px-1"
                  style="min-width: 4vw; width: 30vw;white-space: nowrap;overflow: hidden;"
                  text-center
                  dark
                  color="blue-grey darken-2"
                  v-on:click="swapImage(`${key}`)"
                >{{pose.alt}}</v-btn>
              </v-flex>
            </v-btn-toggle>
          </v-layout>
        </v-container>
      </div>

      <div class="audioContainerPortrait">
        <v-container>
          <v-flex xs12>
            <v-card dark color="primary">
              <v-card-text class>
                <audio class="audioControls" loop autoplay controls :src="backgroundAudio"></audio>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-container>
      </div>
    </div>

    <!-- *** LANDSCAPE *** -->

    <div v-if="window.width > window.height" class="landscape">
      <div class="overlayTextLandscape">
        <h3>{{title.toUpperCase()}}</h3>
        <!-- - {{contents.toUpperCase()}} -->
      </div>
      <div class="bgVideoContainerLandscape">
        <div>
          <video
            v-on:pause="updatePaused"
            v-on:ended="videoEnded"
            :id="videoId"
            controls
            :src="backgroundVideoSrc"
            type="video/mp4"
            :poster="posterImage"
          ></video>
        </div>
      </div>

      <div class="gridContainerLandscape">
        <v-container grid-list-md>
          <v-layout>
            <v-btn-toggle
              v-model="toggle_exclusive"
              grid-list-md
              style="grid-template-columns: auto auto auto;display: grid;position: absolute; top: 0%; left: 0%; width: 40%;"
            >
              <v-flex v-for="(pose, key) in backgrounds" :key="key">
                <v-btn
                  style="min-width: 4.5vw; width: 12.5vw;white-space: nowrap;overflow: hidden;height: 10vh;font-size: 0.75rem;"
                  text-center
                  dark
                  color="blue-grey darken-2"
                  v-on:click="swapImage(`${key}`)"
                >{{pose.alt}}</v-btn>
              </v-flex>
            </v-btn-toggle>
          </v-layout>
        </v-container>

        <div class="audioContainerLandscape">
          <v-container>
            <v-flex xs12>
              <v-card dark color="rgba(0, 0, 0, 0.0)">
                <v-card-text>
                  <audio class="audioControls" loop autoplay controls :src="backgroundAudio"></audio>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-container>
          <v-card v-if="displayOrientationMsg" dark color="orange darken-4" justify="space-around">
            <v-card-text text-center class="justify-center">
              <b>If using a Tablet, Turn Your Device Landscape for Best Results</b>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <!-- <router-view> render children components -->
    <router-view></router-view>
  </div>
</template>

<script>
// import MarqueeText from "vue-marquee-text-component";
// import VueAudio from "vue-audio";
// import { MobileOrientation } from "mobile-orientation";
import { isMobile } from "mobile-device-detect";

import bg1 from "@/assets/video/DownwardFacingDog.mp4";
import bg2 from "@/assets/video/CobblersPose.mp4";
import bg3 from "@/assets/video/ChildPose.mp4";
import bg4 from "@/assets/video/CobraPose.mp4";
import bg5 from "@/assets/video/LyingDownPose.mp4";
import bg6 from "@/assets/video/MountainPose.mp4";
import bg7 from "@/assets/video/TrianglePose.mp4";
import bg8 from "@/assets/video/ChairPose.mp4";
import bg9 from "@/assets/video/WarriorPose.mp4";
// import bg10 from "@/assets/video/TreePose.mp4";

import a1 from "@/assets/audio/yoga/DeeperStrengthBjornAlva.mp3";
import a2 from "@/assets/audio/yoga/TibetMeditationBjornAlva.mp3";
import a3 from "@/assets/audio/yoga/MeditativeMoodsPhilipAyers.mp3";
import a4 from "@/assets/audio/yoga/CottonDreamsElmLake.mp3";
import a5 from "@/assets/audio/yoga/StepsToMindfulnessCalmShores.mp3";
import a6 from "@/assets/audio/yoga/StateOfZenMandalaDreams.mp3";
import a7 from "@/assets/audio/yoga/YogaSpiritSpiritsOfOurDreams.mp3";
import a8 from "@/assets/audio/yoga/EarlyRisingCoraZea.mp3";
import a9 from "@/assets/audio/yoga/MindsTraversedSpiritsOfOurDreams.mp3";

import pi1 from "@/assets/VideoPosters/poster1.png";
import pi2 from "@/assets/VideoPosters/poster2.png";
import pi3 from "@/assets/VideoPosters/poster3.png";
import pi4 from "@/assets/VideoPosters/poster4.png";
import pi5 from "@/assets/VideoPosters/poster5.png";
import pi6 from "@/assets/VideoPosters/poster6.png";
import pi7 from "@/assets/VideoPosters/poster7.png";
import pi8 from "@/assets/VideoPosters/poster8.png";
import pi9 from "@/assets/VideoPosters/poster9.png";

// import BGOverlay from "@/components/static/BGOverlay";
import yogaStore from "@/store/yoga";

export default {
  components: {
    // BGOverlay /* same as: BGOverlay: BGOverlay, */,
    // MarqueeText: MarqueeText,
    // "vue-audio": VueAudio
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      text: "center",
      icon: "justify",
      toggle_exclusive: 0 /* 0 = 1st btn */,
      displayOrientationMsg: false,
      // orientation: new MobileOrientation(),
      videoId: "videoControls",
      videoElement: null,
      videoPaused: null,
      videoLaunched: false,
      videoLoaded: false,
      videoRewatch: false,
      // videoEnded: false,
      selected: 0,
      videoSource: this.backgroundVideoSrc,
      // title: yogaStore.data.textOverlays[0].title,
      // contents:  yogaStore.data.textOverlays[0].contents
      imageLoaded: false
      // file1: "dist/sample.mp3",
      // backgroundAudio: a1,
      // scrollImage: bg1
    };
  },
  computed: {
    isMobileCheck() {
      return isMobile;
    },
    // isPortrait() {
    //   return this.orientation.isPortrait;
    // },
    // isLandscape() {
    //   return this.orientation.isLandscape;
    // },
    playing() {
      return !this.paused;
    },
    backgroundVideoSrc() {
      return this.backgrounds[this.selected].videoName;
    },
    backgroundVideoAlt() {
      return this.backgrounds[this.selected].alt;
    },
    backgroundAudio() {
      return this.backgrounds[this.selected].audio;
    },
    title() {
      return yogaStore.data.textOverlays[this.selected].title;
    },
    posterImage() {
      return this.backgrounds[this.selected].posterImage;
    },
    contents() {
      return yogaStore.data.textOverlays[this.selected].contents;
    },
    backgrounds: function() {
      return [
        { videoName: bg1, id: 1, alt: "Facing", audio: a1, posterImage: pi1 },
        { videoName: bg2, id: 2, alt: "Cobblers", audio: a2, posterImage: pi2 },
        { videoName: bg3, id: 3, alt: "Child", audio: a3, posterImage: pi3 },
        { videoName: bg4, id: 4, alt: "Cobra", audio: a4, posterImage: pi4 },
        { videoName: bg5, id: 5, alt: "Lying", audio: a5, posterImage: pi5 },
        { videoName: bg6, id: 6, alt: "Mountain", audio: a6, posterImage: pi6 },
        { videoName: bg7, id: 7, alt: "Triangle", audio: a7, posterImage: pi7 },
        { videoName: bg8, id: 8, alt: "Chair", audio: a8, posterImage: pi8 },
        { videoName: bg9, id: 9, alt: "Warrior", audio: a9, posterImage: pi9 }
      ];
    }
  },
  mounted() {
    this.imageLoaded = true;
    // setTimeout(() => {
    //   this.displayOrientationMsg = false;
    // }, 5000);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.videoPaused = event.target.paused;
      console.log("Video Pause");
    },
    play() {
      this.videoElement.play();
    },
    pause() {
      this.videoElement.pause();
    },
    videoEnded(event) {
      //this.videoElement = player.target;
      // this.player = player;
      //this.videoLoaded = true;
      // this.videoElement = event.target;
      // this.videoElement.poster = this.posterImage;
      // this.videoSource = this.backgroundVideoSrc;
      // this.swapImage(this.selected);
      console.log("Video Ended");
    },
    swapImage(bgId) {
      this.selected = bgId;
    },
    onChange(e, angle) {
      console.log(e, angle);
    }
  },
  watch: {}
};

// var video = document.getElementById('video');
// video.play();
// video.addEventListener('ended',function(){
//     window.location = 'http://www.google.com';
// });
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
vue-audio {
  padding: 2.5%;
}
.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #0d4777;
  color: #0d4777;
}
#YogaSession {
  position: relative;
  font-size: 1.5rem;
  font-weight: bolder;
  top: 2rem;
  left: 1rem;
}
.videoContainer {
  position: absolute;
  bottom: 17%;
  left: 0%;
  width: 40%;
}
figure {
  padding: 1rem;
}

#videoControls {
  width: 100%;
}

// .videoControls {
//   position: relative;
//   top: 5%;
//   right: 0%;
// }
.audioControls {
  width: 100%;
}
.bgVideoContainerPortrait {
  max-height: 40vh;
  background-color: #0d4777;
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute;
  top: 5.9vh;
  right: 0%;
  width: 100%;
  // max-height: 600px;
  overflow-y: hidden;
}
.overlayTextPortrait {
  background-color: #0b365b;
  // opacity: 0.75;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  text-align: center;
  z-index: 9;
  padding: 0.25rem;
  line-height: 5vh;
}
.gridContainerPortrait {
  position: absolute;
  top: 39vh;
  left: 1%;
  width: 98%;
  // display: grid;
  // grid-template-columns: auto auto auto;
  background-color: #0d4777;
  // padding: 0.9rem;
  padding-bottom: 40vh;
}
.audioContainerPortrait {
  position: fixed;
  bottom: 6.5vh;
  left: 0%;
  width: 100%;
  text-align: center;
}

/* LANDSCAPE */
.overlayTextLandscape {
  background-color: #0b365b;
  opacity: 0.75;
  position: absolute;
  top: 0%;
  left: 42%;
  width: 58%;
  font-size: 0.9rem;
  text-align: center;
  z-index: 9;
  padding: 0.25rem;
  line-height: 2.5vh;
}
.bgVideoContainerLandscape {
  // background-color: #2196f3;
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute;
  top: 5vh;
  right: 0%;
  width: 58%;
  // max-height: 600px;
  overflow-y: hidden;
}
.gridContainerLandscape {
  position: absolute;
  top: 7vh;
  left: 0%;
  width: 42%;
  // display: grid;
  // grid-template-columns: auto auto auto;
  background-color: #0d4777;
  padding: 0.9rem;
  padding-bottom: 40vh;
}
.audioContainerLandscape {
  // position: fixed;
  // bottom: 10vh;
  // left: 0%;
  width: 100%;
  margin-top: 22.5vh;
  padding-top:4vh;
  text-align: center;
}
</style>