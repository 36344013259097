import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                // primary: '#082945',156bb7
                primary: '#0d4777',
                secondary: '#73808c',
                accent: '#9c27b0',
                error: '#f44336',
                warning: '#ff5722',
                info: '#2196f3',
                success: '#4caf50'
            }
        }
    }
})
