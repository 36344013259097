<template>
  <v-app>
    <Header :title="this.$route.name" :buttons="buttons[this.$route.name]" />

    <v-content style="background: #0d4777;"> 
      <router-view></router-view>
      
    </v-content>

    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/static/Header";
import Footer from "@/components/static/Footer";


export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    buttons: {
      Mind: [
        {
          name: "affirmations",
          pathName: "Affirmations",
          params: {}
          // params: {mID: 1}
        },
        // {
        //   name: "psychology",
        //   pathName: "",
        //   params: {}
        // },
        // {
        //   name: "emotions",
        //   pathName: "",
        //   params: {}
        // },
        // {
        //   name: "vocabulary",
        //   pathName: "",
        //   params: {}
        // }
      ],
      Body: [
        // {
        //   name: "affirmations",
        //   pathName: "Affirmations",
        //   params: {mID: 1}
        // },
        
        // {
        //   name: "Meditation Calendar",
        //   pathName: "MeditationCalendar",
        //   params: {}
        // },
        {
          name: "yoga",
          pathName: "Yoga",
          params: {}
        },
        
        //   name: "geometry",
        //   pathName: "Geometry",
        //   params: {}
        // }
      ],
      Soul: [
        // {
        //   name: "affirmations",
        //   pathName: "Affirmations",
        //   params: {mID: 1}
        // },
        {
          name: "meditation",
          pathName: "Meditation",
          params: {}
          // params: {mID: 1}
        },
        // {
        //   name: "insights",
        //   pathName: "Insights",
        //   params: {}
        // },
        // {
        //   name: "guidance",
        //   pathName: "",
        //   params: {}
        // }
      ]
    }
  })
};
</script>
