<template>
  <div class="home">
    <div class="px-5">
      <v-container style="padding-bottom: 5vh">
        <h1>
          This is Your Free Online Go-To App for Affirmations, Yoga and How To
          Do Meditation for the Morning, Daytime or Evening (basically Anytime)
          At Home, in the Garden or the Office.
        </h1>

        <div style="margin-left: 17.5%" id="isMobile" v-if="isiOSSafari">
          <img
            :src="quickAccessiPhonePopup"
            width="75%"
            alt="Add MBS to your home screen for quick access. Tap the Share Icon, then Add to Home Screen Icon"
          />
        </div>
        <br v-else />
        <h3>Energy Flows Where Focus Goes. Use the Buttons Here or Below</h3>
        <v-spacer></v-spacer>
        <router-link
          v-for="(link, key) in links"
          :key="key"
          :to="{ name: link.pathName, params: link.params }"
          text-center
          class="justify-center text-center"
          style="padding: 0.75rem"
        >
          <span>
            <img
              :src="link.button"
              :alt="link.alt"
              width="25%"
              style="cursor: pointer"
            />
          </span>
        </router-link>
        <br />

        <!-- <table>
            <tr style="margin-left: 1%">
              <td style="width: 33%">
                <router-link :to="links[0].pathName">
                <v-btn>
                  <img :src="yoga" alt="Yoga" width="33%" />
                </v-btn>
                </router-link>
              </td>
              <td style="width: 33%">
                <img :src="meditation" alt="Meditation" width="33%" />
              </td>
              <td style="width: 33%">
                <img :src="icons" alt="Affirmations" width="33%" />
              </td>
            </tr>
        </table>-->
        <h3>
          Navigate yourself through the daily bite size sessions, at your own
          pace. Mind (for Affirmations), Body (for Yoga), Soul (for Meditation).
        </h3>
        <br />
        <h3>
          Most of us would like Good Health and Prosperity. Along with
          Happiness, Excitement and Enjoyment. Ultimately leading to Fulfilment,
          Regardless of the Circumstances.
        </h3>
        <br />
        <h3>
          Throughout life we will experience different feelings. Most Feelings
          are the route of one of the following Feelings: Lust, Envy, Anger,
          Attachment and Ego.
        </h3>
        <br />
        <h3>
          <b
            >How do we control these feelings so we can live a more balanced
            life, regardless of the circumstances or situation.</b
          >
        </h3>
        <br />
        <h3>
          A good starting point is being aware of the Mind Body Soul, and feed
          each with the food it requires.
        </h3>

        <div class="donateButton">
          
          <!-- <v-container>
            <v-flex xs12>
              <v-card dark color="primary">
                <v-card-text class="px-3">
                  <i>There is no obligation to donate. The maximum accepted is one pound which allows the Author to continually improve and add new contents to this Website/App. Thank You :)</i>
                  <v-spacer></v-spacer>
                  <br />
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="AND8A55TB3MPL" />
                    <input
                      style="border-radius: 0.75rem;width:25vh"
                      type="image"
                      :src="paypalDonateButton"
                      border="0"
                      name="submit"
                      title="Donate Now - PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img alt border="0" :src="paypalPixel" width="1" height="1" />
                  </form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-container> -->
        </div>
        <br />
      </v-container>
    </div>

    <!-- <v-card class="px-5 my-5 elevation-15">
      <h3>Mind (doubleClick)</h3>
    <ul>-->
    <!-- This time, we wanted to use object.id instead of array index as a key -->
    <!-- Remember, all id should be unique -->
    <!-- <li v-for="mec in Mind" :key="mec.id">
          <div class="link" @dblclick="goToMind(mec.id)">
            Go To Mechanic: {{ mec.name.toUpperCase() }}
          </div>
        </li>
      </ul>
    </v-card>-->

    <!-- <v-card class="px-5 my-5 elevation-15">
      <h3>Go To Mind X sec later after clicking button</h3>
      <h4>With dynamic Mind id (params)</h4>
      <v-form>
        <input type="number" v-model="wait" placeholder="wait x sec" />
        <input type="number" v-model="mecID" placeholder="mecID" />
      </v-form>
      <v-btn :disabled="!wait || wait < 0 || !mecID" @click="waitXsec(wait, mecID)">
        Wait {{ wait ? wait : 0 }} Sec
      </v-btn>
    </v-card>-->
  </div>
</template>

<script>
// var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
// var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// if (isSafari && iOS) {
//     alert("You are using Safari on iOS!");
// } else if(isSafari) {
//     alert("You are using Safari.");
// }

// var userAgent = window.navigator.userAgent;

// if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
//    alert("You are using Safari on iOS!");
// }
// else {
//    alert("Else");
// }

import yoga from "@/assets/VideoPosters/yogaButton.png";
import meditation from "@/assets/VideoPosters/meditationButton.png";
import affirmation from "@/assets/VideoPosters/affirmationButton.png";
import displayPWAImage from "@/assets/quickAccessiPhone.png";
import paypalDonateButton from "@/assets/paypalButton.png";
import paypalPixel from "@/assets/PaypalPixel.gif";

// import { isMobile } from "mobile-device-detect";
// import { DeviceTypeEnum } from 'vue-device-detector'

export default {
  name: "Home",
  data() {
    return {
      userAgent: window.navigator.userAgent,
      // deviceTypeEnum: DeviceTypeEnum,
      quickAccessiPhonePopup: displayPWAImage,
      // isMobile: isMobile,
      affirmation: affirmation,
      yoga: yoga,
      meditation: meditation,
      paypalDonateButton: paypalDonateButton,
      paypalPixel: paypalPixel,
      wait: null,
      mecID: null,
      counter: 0,
      links: [
        {
          pathName: "Mind",
          params: { id: 1 },
          alt: "Affirmation",
          button: affirmation,
        },
        {
          pathName: "Body",
          params: {},
          alt: "Yoga",
          button: yoga,
        },
        {
          pathName: "Soul",
          params: {},
          alt: "Meditation",
          button: meditation,
        },
      ],

      Mind: [
        {
          id: 10,
          name: "a",
        },
        {
          id: 20,
          name: "b",
        },
        {
          id: 30,
          name: "c",
        },
      ],
    };
  },
  computed: {
    isiOSSafari() {
      if (this.userAgent.match(/iPad/i) || this.userAgent.match(/iPhone/i)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToMind(id) {
      this.$router.push({ name: "Mind", params: { id } });
    },
    waitXsec(sec, mecID) {
      setTimeout(() => {
        this.goToMind(mecID);
      }, sec * 1000);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bolder;
}

h2 {
  font-size: 1.25rem;
  font-weight: bolder;
}
.donateButton {
  padding-bottom: 7vh;
  padding-top: 15vh;
  text-align: center;
}
.link {
  cursor: pointer;
}
.link:hover {
  color: red;
  text-decoration: underline;
}
</style>
