<template>
  <div>
    <v-container>
      <v-row>
        <!-- <v-flex xs12>
          <v-card dark color="primary">
            <v-card-text class="px-0">
              <audio id="audioControls" controls :src="chosenMusic"></audio>
              ({{chosenMusic}})
            </v-card-text>
          </v-card>
        </v-flex>-->

        <div class="audioContainerPortrait">
          <v-container>
            <v-flex xs12>
              <v-card dark color="primary">
                <v-card-text class="px-0">
                  <audio class="audioControls" loop autoplay controls :src="chosenMusic"></audio>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-container>
        </div>

        <div class="affirmationContainer">
          <v-card>
            <v-textarea rows="7" readonly class="px-5 affirmationText" v-model="affResponse"></v-textarea>
            <!-- TODO: Could you please, put these inside an array of objects -->
            <!-- This kind of approach doesn't use in the most of the projects -->
            <!-- However it is going to be really nice exercise -->
            <!-- It contains really hard parts/concerns however you don't need these while -->
            <!-- creating this business logic. -->
            <!-- I'll let you know these concerns when it is done -->
            <!-- I have already built this one but let you try also. -->
          </v-card>
        </div>

        <div class="buttonsContainer">
          <v-container>
            <v-layout row class="text-center">
              
              <div style="width: 100%;text-align: center">
                <v-btn style="width: 75%;text-align: center" @click="affAutoSelectSpeakStatusClick">
                  <v-icon
                    block
                    dark
                    color="blue-grey darken-2"
                    justify="space-around"
                    class="align-center justify-center px-3"
                  >mdi-shuffle</v-icon>
                  <div style="font-size: 0.8rem;">Shuffle</div>
                </v-btn>
              </div>

              <div style="width: 100%;text-align: center;margin-top: 0.5rem;">
                <v-btn
                  style="width: 75%;text-align: center"
                  @click="affAutoSpeakStatus = !affAutoSpeakStatus"
                >
                  <v-icon
                    block
                    dark
                    color="blue-grey darken-2"
                    justify="space-around"
                    class="align-center justify-center px-3"
                  >mdi-play</v-icon>
                  <div
                    style="font-size: 0.79rem;"
                  >{{affAutoSpeakStatus ? ' Auto Shuffle On' : ' Auto Shuffle Off'}}</div>
                </v-btn>
              </div>

              <div style="width: 100%;text-align: center;margin-top: 0.5rem;">
                <v-btn style="width: 75%;text-align: center" @click="previousAff">
                  <v-icon
                    block
                    dark
                    color="blue-grey darken-2"
                    justify="space-around"
                    class="align-center justify-center px-3"
                  >mdi-arrow-left</v-icon>Previous
                </v-btn>
              </div>

              <div style="width: 100%;text-align: center;margin-top: 0.5rem;">
                <v-btn style="width: 75%;text-align: center" @click="nextAff">
                  <v-icon
                    block
                    dark
                    color="blue-grey darken-2"
                    justify="space-around"
                    class="align-center justify-center px-3"
                  >mdi-arrow-right</v-icon>Next
                </v-btn>
              </div>
            </v-layout>
          </v-container>
        </div>
      </v-row>

       <div class="bubbles-container">
      <svg
        class="bubbles"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 701 1024"
        style="overflow: visible;"
      >
        <g class="bubbles-large" stroke-width="7">
          <g>
            <g transform="translate(10 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(373 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(408 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(621 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(179 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
        </g>

        <g class="bubbles-small" stroke-width="4">
          <g>
            <g transform="translate(147 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(255 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(573 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(429 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(91 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(640 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(321 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(376 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(376 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(497 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
        </g>
      </svg>
    </div>
    
    </v-container>
  </div>
</template>

<script>
import quoStaticData from "@/assets/json/quotesDataset";
import affStaticData from "@/assets/json/affirmationDataset";

import a1 from "@/assets/audio/affirmations/SoulMantraHeathCantu.mp3";
import a2 from "@/assets/audio/affirmations/NightWalkGavinLuke.mp3";
import a3 from "@/assets/audio/affirmations/SomethingMagicalChristianAndersen.mp3";

const staticData = quoStaticData.concat(affStaticData);

// import jQuery from "jquery";

export default {
  props: ["buttons", "title"],
  data() {
    return {
      musicList: [a1, a2, a3],
      chosenMusic: "",
      isLoading: true,
      name: "",
      selectedVoice: 0,
      // synth: window.speechSynthesis,
      voiceList: [],
      // greetingSpeech: new window.SpeechSynthesisUtterance(),

      // First of all you need to set these as a method not string, such as this.previousAff()
      // Second, if you put () at the end of method, you will run this function when this object created which we don't want to do that.
      // we want to call it when the button clicked not object created. Let's remove (), such as this.previousAff
      // Try to take a look at console log with (), you will see previousAff called lines when page loaded.

      // This is all working fine as expected on the surface, if you click buttons you will see all buttons can be clicked and working (have a look at console)
      // The weird thing is, let's try to render this affButtons object
      // As you see, the response is [ { "title": "Previous" }, { "title": "Next" }, {}, { "title": "Speak" }, { "title": "Update" }, { "title": "Delete" } ]
      // The question is where is the method fields, and where is the title for affSpeakStatus (it renders empty object)
      // Also we can't see even the dynamic text on the button as well as you detected.
      // Let's sort out button text issue first.
      // GO TO NEXT COMMIT

      // ----------------- COMMIT 2 ------------------------
      // Let's put all this static button array of objects into a method and return the array
      // Call setAffButtons when instance created
      // At this point actually there is no difference than putting this array into data.
      // Again we can use method functionalities, this time at least we can see Speak Off (which is coming from dynamic field of affSpeakStatus)
      // but the problem is again we can't see any changes when we clicked affToggleBtn.
      // remember it is working properly, you can see if you render {{affSpeakStatus}} in the template, problem is, it is not reflected on button text.
      // solution is easy, let's define a watcher

      affButtons: [],
      // You need to register staticData as a Vue Data
      affData: staticData,
      // Thanks to 2 ways binding, we can change this value from textarea html element
      // and with js (vue) methods
      affResponse: null,
      counter: 0,
      affSpeakStatus: false,
      affAutoSpeakStatus: false
    };
  },
  mounted() {
    //   this.voiceList = this.synth.getVoices();
    this.picker();
    //   if (this.voiceList.length) {
    //     this.isLoading = false;
    //   }
    //   this.synth.onvoiceschanged = () => {
    //     this.voiceList = this.synth.getVoices();
    //     // give a bit of delay to show loading screen
    //     // just for the sake of it, I suppose. Not the best reason
    //     setTimeout(() => {
    //       this.isLoading = false;
    //     }, 800);
    //   };
    //   this.listenForSpeechEvents();
  },
  created() {
    // this.setAffButtons();
    this.nextAff();
  },
  watch: {
    //   // this should be same name with the data/prop name
    affSpeakStatus(val) {
      if (val === true) {
        // this.greet();
      }
    },
    affAutoSpeakStatus(val) {
      if (val === true) {
        this.affAutoSpeakStatusClick();
      }
    }

    //     console.log('You can listen changed value if you want, it is redundant for this example though', val)
    //     // We are overwriting the button object when the affSpeakStatus changed, because the reason of the problem
    //     // not seeing changed value of affSpeakStatus inside affButtons related object
    //     // when data changed, the button array/object which has dependent dynamic field isn't updated. It is non-reactive
    //     // This totally solve every problems from the functionality point of view.

    //     /* this.setAffButtons() */

    //     // If you want you can directly reach affButtons array, select the particular object
    //     // update only that object instead of overwriting whole array.
    //     this.affButtons[2].title = val
    //   }
  },
  methods: {
    picker: function() {
      const chosenNumber = Math.floor(Math.random() * this.musicList.length);
      this.chosenMusic = this.musicList[chosenNumber];
    },
    /**
     * React to speech events
     */

    // listenForSpeechEvents() {
    //   this.greetingSpeech.onstart = () => {
    //     this.isLoading = true;
    //   };

    //   this.greetingSpeech.onend = () => {
    //     this.isLoading = false;
    //   };
    // },
    affAutoSelectSpeakStatusClick() {
      this.counter = Math.floor(Math.random() * 24) + 1; //this.affData.length);

      this.setAffResponse(this.counter);
      if (this.affSpeakStatus === true) {
        // this.greet();
      }
    },
    affAutoSpeakStatusClick() {
      if (this.affAutoSpeakStatus) {
        setTimeout(() => {
          this.isLoading = false;

          this.counter = Math.floor(Math.random() * 24) + 1; //this.affData.length);

          this.setAffResponse(this.counter);
          if (this.affSpeakStatus === true) {
            // this.greet();
          }

          this.affAutoSpeakStatusClick();
        }, 8000);
      }
    },
    // greet() {
    //   this.greetingSpeech.text = this.affResponse;

    //   this.greetingSpeech.voice = this.voiceList[this.selectedVoice];

    //   this.synth.speak(this.greetingSpeech);
    // },
    // setAffButtons() {
    //   this.affButtons = [
    //     { method: this.previousAff, title: "Previous" },
    //     { method: this.nextAff, title: "Next" },
    //     { method: this.affToggleBtn, title: this.affSpeakStatus },
    //     { method: this.affSpeak, title: "Speak" },
    //     { method: this.affUpdate, title: "Update" },
    //     { method: this.affDelete, title: "Delete" }
    //   ]
    // },
    setAffResponse(id) {
      const data = this.affData.find(d => d.id == id);
      this.affResponse = data.affirmation;
    },
    previousAff() {
      console.log("previousAff called");
      if (this.counter > 1) {
        this.counter--;
        this.setAffResponse(this.counter);

        if (this.affSpeakStatus === true) {
          // this.greet();
        }
      }
    },
    nextAff() {
      console.log("nextAff called");
      if (this.counter < this.affData.length) {
        this.counter++;
        this.setAffResponse(this.counter);
        if (this.affSpeakStatus === true) {
          // this.greet();
        }
      }
    },
    affSpeak() {
      console.log("affSpeak called");
    }
  }
};

/* var jQueryvoicelist = jQuery("#voices");

jQuery(document).ready(function() {
  jQuery(function() {
    if ("speechSynthesis" in window) {
      jQuery("#speakAff").click(function() {
        var text = jQuery("#affirmationText").val();
        var msg = new SpeechSynthesisUtterance();
        var voices = window.speechSynthesis.getVoices();
        msg.voice = voices[jQuery("#voices").val()];
        msg.rate = jQuery("#rate").val() / 10;
        msg.pitch = jQuery("#pitch").val();
        msg.text = text;

        msg.onend = function(e) {
          console.log("Finished in " + event.elapsedTime + " seconds.");
        };

        speechSynthesis.speak(msg);
      });

      jQuery("#speak").click(function() {
        var text = jQuery("#message").val();
        var msg = new SpeechSynthesisUtterance();
        var voices = window.speechSynthesis.getVoices();
        msg.voice = voices[jQuery("#voices").val()];
        msg.rate = jQuery("#rate").val() / 10;
        msg.pitch = jQuery("#pitch").val();
        msg.text = text;

        msg.onend = function(e) {
          console.log("Finished in " + event.elapsedTime + " seconds.");
        };

        speechSynthesis.speak(msg);
      });
    } else {
      alert("Speech in Browser-App Not Supported");
    }
  });
}); */
</script>

<style lang="scss" scoped>
.btn-success {
  background: #43c6ac;
  border-color: #43c6ac;
  cursor: pointer;
}
.audioContainerPortrait {
  width: 100%;
  text-align: center;
}
.audioControls {
  width: 100%;
}
.affirmationText {
  font-size: 1.5rem;
}
.affirmationContainer {
  margin-left: 5%;
  width: 90%;
  text-align: center;
}
.buttonsContainer {
  width: 90%;
  margin-left: 5%;
  padding-bottom: 10vh;
}
.form-container {
  // max-width: 90%;
  // min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  padding: 30px;
  max-width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.1);
}

.bubbles-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 15rem;
    transform: translateX(-50%);
	opacity: 0.75;
	overflow: visible;
}

.bubbles {
	width: 100%;
	height: auto;
	
	circle {
		stroke: white;
		fill: none;
	}
	
	> g > g:nth-of-type(3n) circle {
		stroke: #87f5fb;
	}
	
	> g > g:nth-of-type(4n) circle {
		stroke: #8be8cb;
	}
	
}

.bubbles-large {
	overflow: visible;

	> g {
		transform: translateY(2048px);
		opacity: 0;
		will-change: transform, opacity;
	}

	g:nth-of-type(1) {
		animation: up 6.5s infinite;
		
		g {
			transform: translateX(350px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}
	
	g:nth-of-type(2) {
		animation: up 5.25s 250ms infinite;
		
		g {
			transform: translateX(450px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}
	
	g:nth-of-type(3) {
		animation: up 6s 750ms infinite;
		
		g {
			transform: translateX(700px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}
	
	g:nth-of-type(4) {
		animation: up 5.5s 1.5s infinite;
		
		g {
			transform: translateX(500px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}
	
	g:nth-of-type(5) {
		animation: up 6.5s 4s infinite;
		
		g {
			transform: translateX(675px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}

}

.bubbles-small {
	overflow: visible;

	> g {
		transform: translateY(2048px);
		opacity: 0;
		will-change: transform, opacity;
	}

	g circle {
		transform: scale(0);
	}

	g:nth-of-type(1) {
		animation: up 5.25s infinite;
		
		g {
			transform: translateX(350px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}

	g:nth-of-type(2) {
		animation: up 5.75s infinite;
		
		g {
			transform: translateX(750px);
		}

		circle {
			animation: wobble 3s infinite ease-in-out;
		}

	}

	g:nth-of-type(3) {
		animation: up 5.25s 250ms infinite;
		
		g {
			transform: translateX(350px);
		}

		circle {
			animation: wobble 3s 250ms infinite ease-in-out;
		}

	}

	g:nth-of-type(4) {
		animation: up 5.75s 325ms infinite;
		
		g {
			transform: translateX(180px);
		}

		circle {
			animation: wobble 3s 325ms infinite ease-in-out;
		}

	}

	g:nth-of-type(5) {
		animation: up 6s 125ms infinite;
		
		g {
			transform: translateX(350px);
		}

		circle {
			animation: wobble 3s 250ms infinite ease-in-out;
		}

	}

	g:nth-of-type(6) {
		animation: up 5.13s 250ms infinite;
		
		g {
			transform: translateX(650px);
		}

		circle {
			animation: wobble 3s 125ms infinite ease-in-out;
		}

	}

	g:nth-of-type(7) {
		animation: up 6.25s 350ms infinite;
		
		g {
			transform: translateX(480px);
		}

		circle {
			animation: wobble 3s 325ms infinite ease-in-out;
		}

	}
	
	g:nth-of-type(8) {
		animation: up 7s 200ms infinite;
		
		g {
			transform: translateX(330px);
		}

		circle {
			animation: wobble 3s 325ms infinite ease-in-out;
		}

	}

	g:nth-of-type(9) {
		animation: up 6.25s 233ms infinite;
		
		g {
			transform: translateX(230px);
		}

		circle {
			animation: wobble 3s 275ms infinite ease-in-out;
		}

	}
	
	g:nth-of-type(10) {
		animation: up 6s 900ms infinite;
		
		g {
			transform: translateX(730px);
		}

		circle {
			animation: wobble 2s 905ms infinite ease-in-out;
		}

	}
	
}

@keyframes wobble {
	
	33% {
		transform: translateX(-50px);
	}
	
	66% {
		transform: translateX(50px);
	}
	
}

@keyframes up {

	0% {
		opacity: 0;
	}
	
	10%, 90% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
		transform: translateY(-1024px);
	}
	
}
</style>
