import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'

import VueFbCustomerChat from './plugins/vue-fb-customer-chat.umd.js'

Vue.use(VueFbCustomerChat, {
  page_id: 104631601913872, //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'en_UK', // default 'en_US'
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
